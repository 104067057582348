/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($, _c, undefined) {
    "use strict";

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.

    var Sage = {
        // All pages
        common: {
            init: function () {
                // JavaScript to be fired on all pages
                var windowWidth = $(window).width();

                $('.hamburger').on('click', function () {
                    $('.topnav').toggleClass('is-active');
                    $('body').toggleClass("no-scroll");
                });
                
                $('.topnav>ul>li a').on('click', function(){
                    $('.topnav').removeClass('is-active');
                    $('body').removeClass("no-scroll");
                });

                $(".topnav button.close").on("click", function () {
                    $('.topnav').toggleClass('is-active');
                    $('body').toggleClass("no-scroll");
                });

                $(window).resize(function () {
                    if ($(window).width() > 968) {
                        $('.topnav').attr('style', '');
                        $('.topnav').removeClass('is-active');
                        $('.hamburger').removeClass('is-active');
                        $('body').removeClass("no-scroll");
                    }
                });

                // Form
                (function () {
                    $("select").selectric();
                })();
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired

                var prevScrollNav = 0;

                $(window).on("scroll", function () {
                    var scrollPosition = $(document).scrollTop();

                    // show/hide navbar
                    // if ($(window).scrollTop() > $('header.header').height()) {
                    // 	$('header').addClass('hide');
                    // } else {
                    // 	$('header').removeClass('hide');
                    // }

                    // if ($(window).scrollTop() >= prevScrollNav) {
                    // 	$('header').removeClass('show');
                    // } else {
                    // 	$('header').addClass('show');
                    // }

                    prevScrollNav = $(window).scrollTop();

                    // pop in items to activate
                    $(".to-activate").each(function () {
                        if (scrollPosition >= $(this).offset().top - 600) {
                            $(this).addClass("active");
                        }
                    });
                });

                $(".open-info").click(function (e) {
                    e.preventDefault();
                    $(".request-info").addClass("show");
                    $('body').addClass("no-scroll");
                });

                $(".request-info .close").click(function (e) {
                    e.preventDefault();
                    $(this).parent(".request-info").removeClass("show");
                    if(!$('.topnav').hasClass("is-active")){
                        $('body').removeClass("no-scroll");
                    }
                });
            }
        },

        // Home page
        page_template_template_home: {
            init: function () {
            },
            finalize: function () {
                // close popups
                $(".popup .close").click(function (e) {
                    e.preventDefault();
                    $(".flexibility .right").attr("data-open", "closed");
                    $(this)
                        .parent(".popup")
                        .removeClass("show");

                    $("html, body").css({
                        overflow: "auto",
                        height: "auto"
                    });
                });

                // professionalism dots
                $(".dots .dot").click(function (e) {
                    e.preventDefault();
                    $(this)
                        .siblings(".popup")
                        .addClass("show");
                });

                // history image stack
                $(".stack img").click(function () {
                    if ($(this).hasClass("top")) {
                        $(this)
                            .removeClass("top")
                            .parent()
                            .prepend(this);
                        $(".stack img:last-child").addClass("top");
                    }
                });

                function updateSlide(floorplan, number) {
                    $(".slider-container .slide-title").text(floorplan.title);
                    console.log("number is " + number);
                    $(".slider-container .floorplan").attr("href", "#popup-" + number);
                    $(".slider-container .floor-img").attr("src", floorplan.image);
                    if ($(".flexibility .right").attr("data-open") === "open") {
                        var popup = $(".slider-container .floorplan").attr("href");
                        $(
                            ".page-template-template-home section.intro .flexibility .boxes .right .popup"
                        ).removeClass("show");
                        $(popup).addClass("show");
                    }
                }

                $.ajax({
                    type: "GET",
                    url:
                        "/wp-content/themes/park-west-theme/lib/FloorPlans/ajaxFloorPlan.php",
                    success: function (data) {
                        var floorplans = JSON.parse(data);

                        if (Object.keys(floorplans).length > 0) {
                            var i = 0;

                            $(".arrows #back").click(function (e) {
                                e.preventDefault();
                                if (i === 0) {
                                    i = Object.keys(floorplans).length - 1;
                                } else {
                                    i--;
                                }

                                updateSlide(floorplans[i], i);
                            });

                            $(".arrows #next").click(function (e) {
                                e.preventDefault();
                                if (i === Object.keys(floorplans).length - 1) {
                                    i = 0;
                                } else {
                                    i++;
                                }
                                updateSlide(floorplans[i], i);
                            });

                            // flexibility floorplan popup click and drag
                            $(".flexibility .popup").draggable({
                                cursor: "crosshair"
                            });
                        }
                    }
                });

                // flexibility floorplan popup
                $(".flexibility .floorplan").click(function (e) {
                    e.preventDefault();
                    $(".flexibility .right").attr("data-open", "open");
                    var popup = $(this).attr("href");
                    $(
                        ".page-template-template-home section.intro .flexibility .boxes .right .popup"
                    ).removeClass("show");
                    $(popup).addClass("show");
                });

                // testimonials click and drag
                $(".testimonial").draggable({
                    containment: "parent",
                    stack: ".testimonial",
                    cursor: "crosshair",
                    distance: 0
                });

                if ($(window).width() > 768) {
                    // experience hover
                    var mouseX;
                    var mouseY;
                    $(document).mousemove(function (e) {
                        mouseX = e.pageX;
                        mouseY = e.pageY;
                    });
                    $(".name").mouseover(function () {
                        var parentOffset = $(this)
                            .parent()
                            .offset();

                        var relX = mouseX - parentOffset.left;
                        var relY = mouseY - parentOffset.top;

                        $(this)
                            .find(".hover")
                            .css({top: relY, left: relX})
                            .fadeIn(0);
                    });
                    $(".name").mouseout(function () {
                        $(this)
                            .find(".hover")
                            .fadeOut(0);
                    });
                } else {
                    $(".name h3").click(function (e) {
                        e.stopPropagation();
                        $(".hover").each(function (e) {
                            $(this).fadeOut(0);
                        });
                        $(this)
                            .siblings(".hover")
                            .toggle();
                    });
                    $(".hover .description .close").click(function (e) {
                        e.preventDefault();
                        $(".name .hover").each(function () {
                            $(this).hide();
                            console.log(this);
                        });
                    });
                    $("html").click(function () {
                        $(".hover").fadeOut(0);
                    });
                }
            }
        },

        // events page
        page_template_template_events: {
            init: function () {
            },
            finalize: function () {
                //  floorplan slides
                function changeFloorPlan(section, dir) {
                    var sliderContainer = $(section).siblings(".slider-container");
                    var slides = $(sliderContainer).children();
                    var slidesCount = slides.length;
                    var currentSlide = $(sliderContainer).find(".current");
                    var currentInd = Number($(currentSlide).attr("data-num"));
                    var slideNum;
                    if (dir === "+") {
                        slideNum = currentInd + 1 > slidesCount ? 1 : currentInd + 1;
                    } else if (dir === "-") {
                        slideNum = currentInd - 1 < 1 ? slidesCount : currentInd - 1;
                    }
                    $(slides).each(function () {
                        if (Number($(this).attr("data-num")) !== slideNum) {
                            $(this).addClass("hideFloorPlan");
                            $(this).removeClass("current");
                        } else {
                            if (slideNum <= slidesCount) {
                                $(this).removeClass("hideFloorPlan");
                                $(this).addClass("current");
                            }
                        }
                    });
                }

                $(".slider-container").each(function () {
                    var children = $(this).children();
                    if ($(children).length <= 1) {
                        $(this)
                            .siblings(".arrows")
                            .hide();
                    }
                });

                $(".arrows .back").click(function (e) {
                    e.preventDefault();
                    changeFloorPlan($(this).parent(), "-");
                });

                $(".arrows .next").click(function (e) {
                    e.preventDefault();
                    changeFloorPlan($(this).parent(), "+");
                });

                // accordions for mobile
                if ($(window).width() < 968) {
                    $(".accordion-content").hide();

                    $(".accordion-control").click(function (event) {
                        event.preventDefault();

                        var $control = $(this);
                        var accordionContent = $control.attr("aria-controls");
                        var isAriaExp = $control.attr("aria-expanded");
                        $control.attr("aria-expanded", !isAriaExp);

                        var isAriaHid = $("#" + accordionContent).attr("aria-hidden");

                        if (isAriaHid == "true") {
                            $("#" + accordionContent).attr("aria-hidden", "false");
                        } else {
                            $("#" + accordionContent).attr("aria-hidden", "true");
                        }
                        $(this)
                            .siblings(".accordion-content")
                            .slideToggle();
                    });
                }

                // close popups
                $(".close").click(function (e) {
                    e.preventDefault();
                    $(this)
                        .parent()
                        .removeClass("show");

                    $("html, body").css({
                        overflow: "auto",
                        height: "auto"
                    });
                });

                // photos popup
                // $(".photos-popup").draggable({
                //   containment: ".content",
                //   cursor: "crosshair",
                //   distance: 0,
                //   stack: ".photos-popup"
                // });

                $(".photos-link").click(function (e) {
                    e.preventDefault();
                    $(this)
                        .siblings(".photos-popup")
                        .addClass("show");
                });

                // // photos swiper
                // var photoSwiper = new Swiper(".swiper-container", {
                //   // Optional parameters
                //   loop: true,

                //   // Navigation arrows
                //   navigation: {
                //     nextEl: ".swiper-button-next",
                //     prevEl: ".swiper-button-prev"
                //   },

                //   slidesPerView: 3,
                //   spaceBetween: -50,
                //   centeredSlides: true,
                //   breakpoints: {
                //     // when window width is <= 320px
                //     568: {
                //       slidesPerView: 1,
                //       spaceBetween: 10,
                //       loop: false
                //     }
                //   }
                // });

                var $sContain = $(".swiper-container");

                $sContain.each(function () {
                    var $this = $(this);
                    var mySwiper = $this.swiper({
                        loop: true,
                        slidesPerView: 3,
                        spaceBetween: -50,
                        centeredSlides: true,
                        breakpoints: {
                            990: {
                                slidesPerView: 1,
                                spaceBetween: 50,
                                centeredSlides: false,
                                loop: false
                            }
                        }
                    });
                    $(this)
                        .find(".swiper-button-prev")
                        .on("click", function (e) {
                            e.preventDefault();
                            mySwiper.slidePrev();
                        });
                    $(this)
                        .find(".swiper-button-next")
                        .on("click", function (e) {
                            e.preventDefault();
                            console.log("clicked");
                            mySwiper.slideNext();
                        });

                    $(".photos-link").click(function (e) {
                        e.preventDefault();
                        mySwiper.update();
                    });
                });

                // if ($(window).width() < 990) {
                //   $(window).trigger("resize");
                // }
                // info popup
                $(".info-popup").draggable({
                    containment: ".content",
                    cursor: "crosshair",
                    distance: 1,
                    stack: ".info-popup"
                });

                $(".text-link").click(function (e) {
                    e.preventDefault();
                    var infoPopup = $(this).siblings(".info-popup");
                    var infoHeight = 100;
                    $(infoPopup)
                        .children()
                        .each(function () {
                            infoHeight += $(this).outerHeight();
                        });
                    $(".info-popup").each(function () {
                        $(this).removeClass("show");
                    });
                    $(infoPopup).addClass("show");
                    $(infoPopup).css("height", infoHeight);
                    console.log(infoHeight);
                });
            }
        },

        // capabilities page
        page_template_template_capabilities: {
            init: function () {
            },
            finalize: function () {
                // background color for each section
                var prodWaypoint = new Waypoint({
                    element: document.getElementById("production"),
                    handler: function () {
                        $("body").css("background-color", "#FF6D4C");
                        $("body section").css("color", "black");
                    },
                    offset: 200
                });

                var floorWaypoint = new Waypoint({
                    element: document.getElementById("floor-plan-setup"),
                    handler: function () {
                        $("body").css("background-color", "#E5DACF");
                        $("body section").css("color", "black");
                    },
                    offset: 200
                });

                var bevWaypoint = new Waypoint({
                    element: document.getElementById("bev-staff"),
                    handler: function () {
                        $("body").css("background-color", "#255451");
                        $("body section").css("color", "white");
                    },
                    offset: 200
                });

                var securityWaypoint = new Waypoint({
                    element: document.getElementById("security"),
                    handler: function () {
                        $("body").css("background-color", "#E9C5A5");
                        $("body section").css("color", "black");
                    },
                    offset: 200
                });

                var securityWaypoint = new Waypoint({
                    element: document.getElementById("planning"),
                    handler: function () {
                        $("body").css("background-color", "#FCE1DA");
                        $("body section").css("color", "black");
                    },
                    offset: 200
                });

                // link hovers
                var mouseX;
                var mouseY;
                $(document).mousemove(function (e) {
                    mouseX = e.pageX;
                    mouseY = e.pageY;
                });
                $(".hover-trigger").mouseover(function () {
                    var parentOffset = $(this)
                        .parent()
                        .offset();

                    var relX = mouseX - (parentOffset.left + 300);
                    var relY = mouseY - parentOffset.top;

                    $(this)
                        .find(".hover")
                        .css({top: relY, left: relX})
                        .fadeIn(0);
                });
                $(".hover-trigger").mouseout(function () {
                    $(this)
                        .find(".hover")
                        .fadeOut(0);
                });
            }
        },

        // history page
        page_template_template_history: {
            init: function () {
            },
            finalize: function () {
                // link hovers
                var mouseX;
                var mouseY;
                $(document).mousemove(function (e) {
                    mouseX = e.pageX;
                    mouseY = e.pageY;
                });
                $(".hover-name").mouseover(function () {
                    var parentOffset = $(this)
                        .parent()
                        .offset();

                    var relX = mouseX - (parentOffset.left + 300);
                    var relY = mouseY - (parentOffset.top + 200);

                    $(this)
                        .find(".hover")
                        .css({top: relY, left: relX})
                        .fadeIn(0);
                });
                $(".hover-name").mouseout(function () {
                    $(this)
                        .find(".hover")
                        .fadeOut(0);
                });
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = funcname === undefined ? "init" : funcname;
            fire = func !== "";
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === "function";

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire("common");

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, "_").split(/\s+/), function (i,
                                                                                      classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, "finalize");
            });

            // Fire common finalize JS
            UTIL.fire("common", "finalize");
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);
})(window.jQuery, window.Clique); // Fully reference jQuery after this point.
